<template>
  <div class="alertasBox">
    <div
      class="listaDropdown"
      @click="toggleLista"
      :id="'BlocoPFAlertas-lista' + tipoAlerta"
    >
      <p class="dropdownText">{{ listaText }}</p>
      <img
        class="iconOpen"
        :src="arrowOpen"
        :style="{ transform: iconRotation }"
      />
    </div>
    <div class="listaBox">
      <B9ItemAlerta
        v-for="(alerta, index) in filteredAlertas"
        :key="index"
        :index="index"
        :alerta="alerta"
        :iconeAlerta="iconeAlerta"
        :tipoAlerta="tipoAlerta"
        @scrollToBlock="scrollToBlock"
      />
    </div>
    <div v-for="(tt, i) in tooltips" :key="i">
      <b-tooltip
      v-if="exibirTootipsConsultas"
        :target="tt.target + tipoAlerta"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import B9ItemAlerta from "@/components/SuperBox/B9ItemAlerta.vue";
import { storeAlertasSuperBoxPJ } from "@/stores/superbox-alertas-pj.js";
import { mapActions, mapWritableState } from "pinia";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B9ListaAlertas",

  components: { B9ItemAlerta },

  data() {
    return {
      arrowOpen: "/img/superbox/arrow-bottom.png",
      iconeAlta: "/img/superbox/alertas-gerais/alertas-alta-prioridade-ativo.png",
      iconeBaixa: "/img/superbox/alertas-gerais/alerta-seta-amarela.svg",
      // iconeBaixa: "/img/superbox/alertas-gerais/alertas-baixa-prioridade-ativo.png",
      iconePositivo: "/img/superbox/alertas-gerais/alertas-positivos-ativo.png",
      showLista: false,
    };
  },

  props: {
    tipoAlerta: String,
    exibirTootipsConsultas: Boolean,
  },

  methods: {
    toggleLista() {
      this.showLista = !this.showLista;
    },

    scrollToBlock(bloco) {
      this.$emit("scrollToBlock", bloco);
    },

    ...mapActions(storeAlertasSuperBoxPJ, ["ativaAlerta"]),
  },

  computed: {
    iconRotation() {
      return this.showLista ? "rotate(180deg)" : "rotate(0deg)";
    },

    listaText() {
      return this.showLista
        ? "Ocultar alertas inativos"
        : "Mostrar alertas inativos";
    },

    // alertStore() {
    //   return alertasSuperBox();
    // },

    listaAlertasComputed() {
      const store = storeAlertasSuperBoxPJ();
      return store.listaAlertas(this.tipoAlerta);
    },

    iconeAlerta() {
      if (this.tipoAlerta == "alta") {
        return this.iconeAlta;
      }
      if (this.tipoAlerta == "baixa") {
        return this.iconeBaixa;
      }
      if (this.tipoAlerta == "positivo") {
        return this.iconePositivo;
      } else {
        return "";
      }
    },

    filteredAlertas() {
      if (this.showLista) {
        return this.listaAlertasComputed.slice().sort((a, b) => {
          if (a.valor === b.valor) {
            return 0;
          }
          if (a.valor) {
            return -1;
          }
          return 1;
        });
      } else {
        return this.listaAlertasComputed.filter(
          (alerta) => alerta.valor === true
        );
      }
    },

    tooltips() {
      const listaGeral = listaTooltips.blocoAlertasGerais;

      return listaGeral.filter((tt) => tt.target == "BlocoPFAlertas-lista");
    },

    ...mapWritableState(storeAlertasSuperBoxPJ, [
      "alertas_altos",
      "alertas_baixos",
      "alertas_positivos",
    ]),
    // filteredAlertas() {
    //   if (this.showLista) {
    //     return this.listaAlertas;
    //   } else {
    //     return this.listaAlertas.filter((alerta) => alerta.valor === true);
    //   }
    // },
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.listaDropdown {
  width: 290px;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdownText {
  margin: 0 !important;
}

.listaBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
